<svg aria-hidden="true" class="w-full h-full animate-spin"
     viewBox="0 0 128 128"
     shape-rendering="geometricPrecision">

  <path class="track" fill="currentFill" fill-rule="evenodd"
        d=" M 64 0
            a 64 64 0 0 1 64 64
            a 64 64 0 0 1 -64 64
            a 64 64 0 0 1 -64 -64
            a 64 64 0 0 1 64 -64
            m 0 16
            a 48 48 0 0 1 48 48
            a 48 48 0 0 1 -48 48
            a 48 48 0 0 1 -48 -48
            a 48 48 0 0 1 48 -48"></path>

  <path class="bar" stroke="currentColor" stroke-width="16" stroke-linecap="round" fill="none"
        d=" M 64 8
            a 56 56 0 0 1 56 56"></path>

</svg>
<span class="sr-only">Loading...</span>